<template>
  <TransitionRoot appear :show="$visible" as="template">
    <Dialog as="div" class="relative z-30 w-full">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full h-full transform bg-white text-left shadow-xl transition-all md:h-min md:max-w-2xl lg:max-w-3xl lg:rounded-md flex cursor-pointer"
              @click="cerrarModal"
            >
              <picture>
                <source
                  srcset="/assets/referidos/instrucciones-referidos.webp"
                  type="image/webp"
                />
                <img
                  src="/assets/referidos/instrucciones-referidos.png"
                  alt="instrucciones-referidos"
                  class="h-full w-full"
                />
              </picture>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import { useStore } from "@nanostores/vue";
  import { modalReferidos, setearModalReferidos } from "@stores/app";
  const $visible = useStore(modalReferidos);
  const cerrarModal = (): void => {
    setearModalReferidos(false);
  };
</script>
